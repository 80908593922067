type Dict<K extends string, T> = {
  [P in K]: T;
};

export enum Department {
  BizDev = "Business Development",
  Rnd = "R&D",
  Marketing = "Marketing",
  Finance = "Finance",
  Engineering = "Engineering",
  Data = "Data",
  Growth = "Growth"
}

export const DepartmentsDictionary: Dict<
  Department,
  "business" | "rnd" | "marketing" | "finance" | "engineering" | "data" | "growth"
> = {
  [Department.BizDev]: "business",
  [Department.Rnd]: "rnd",
  [Department.Marketing]: "marketing",
  [Department.Finance]: "finance",
  [Department.Engineering]: "engineering",
  [Department.Data]: "data",
  [Department.Growth]: "growth"

};

export const DepartmentStringToEnum = {
  "Business Development": Department.BizDev,
  "R&D": Department.Rnd,
  "Marketing": Department.Marketing,
  "Finance": Department.Finance,
  "Engineering": Department.Engineering,
  "Data": Department.Data,
  "Growth": Department.Growth
};

export type DepartmentName =
  | "Business Development"
  | "R&D"
  | "Marketing"
  | "Finance"
  | "Engineering"
  | "Data"
  | "Growth"
  ;


