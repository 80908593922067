import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const CareersContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="main" {...props} />
))`
  &.careers-container {
    color: ${({ theme: { palette } }: ThemeType) => palette.primary.main};
    background-color: ${({ theme: { palette } }: ThemeType) =>
      palette.p_pink.background};

    min-height: 100vh;
  }
`;
