import * as React from "react";
import Box from "@mui/material/Box";
import Link from "components/common/link";
import LocationTag from "../../../common/location-tag";
import DepartmentTag from "../../../common/department-tag";
import { PositionCardContainer } from "../styles";
import { PositionCardProps } from "../types";

const PositionCard: React.FC<PositionCardProps> = ({
  department,
  location,
  title,
  url,
}) => {
  return (
    <Link to={url}>
      <PositionCardContainer className="position-card">
        <h5 className="position-title">{title ?? ""}</h5>
        <Box className="tags-container">
          <LocationTag location={location ?? ""} />
          <DepartmentTag department={department ?? ""} />
        </Box>
      </PositionCardContainer>
    </Link>
  );
};

export default PositionCard;
