import * as React from "react";
import Box from "@mui/material/Box";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Link from "components/common/link";
import { TopContainer } from "../styles";
import { TopProps } from "../types";
import BackArrow from "assets/svg/arrow-short.svg";
import { PrismicRichText } from "@prismicio/react";

const Top: React.FC<TopProps> = ({ isPosition }) => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicCareersPage {
        nodes {
          data {
            page_title {
              richText
            }
            team_image {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;
  return (
    <TopContainer className="home-top">
      <GatsbyImage
        className={"background"}
        alt={""}
        image={document.team_image.gatsbyImageData}
      />
      <Box className="page-title">
        <PrismicRichText field={document.page_title.richText} />
      </Box>

      {isPosition && (
        <Box className="max-width">
          <Link className="back-to-careers-link" to="/careers">
            <BackArrow className="back-to-careers-icon" />
            <Box className="back-to-open-positions">Open positions</Box>
          </Link>
        </Box>
      )}
    </TopContainer>
  );
};

export default Top;
