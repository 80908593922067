import * as React from "react";
import Careers from "../components/pages/careers";

interface CareersProps {}

const CareersPage: React.FC<CareersProps> = () => {
  return <Careers />;
};

export default CareersPage;
