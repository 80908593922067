import * as React from "react";
import WorkingWithUs from "./content";
import PositionsList from "./position-list";
import { DescriptionContainer } from "./styles";

interface DescriptionProps {}

const Description: React.FC<DescriptionProps> = () => {
  return (
    <DescriptionContainer className="careers-description">
      <WorkingWithUs />
      <PositionsList />
    </DescriptionContainer>
  );
};

export default Description;
