import * as React from "react";
import LocationOnIcon from "assets/svg/location.svg";
import { LocationTagContainer } from "../styles";

interface LocationTagProps {
  location: string;
}

const LocationTag: React.FC<LocationTagProps> = ({ location }) => {
  return (
    <LocationTagContainer className="location-tag">
      <LocationOnIcon />
      <span>{location}</span>
    </LocationTagContainer>
  );
};

export default LocationTag;
