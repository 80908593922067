import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const DepartmentTagContainer = styled(
  (
    props: BoxProps &
      WithThemeProps & {
        departmentName:
          | "business"
          | "rnd"
          | "marketing"
          | "finance"
          | "engineering"
          | "data"
          | "growth";
      }
  ) => <Box component="div" {...props} />
)`
  &.department-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(0.5, 1.1)};
    border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    background-color: ${({ theme, departmentName }) =>
      theme.palette.department[departmentName]
        ? theme.palette.department[departmentName].background
        : "#BEE8C2"};
    color: ${({ theme, departmentName }) =>
      theme.palette.department[departmentName]
        ? theme.palette.department[departmentName].text
        : "#416720"};
  }
`;

export const LocationTagContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.location-tag {
    display: flex;
    align-items: center;
    font-size: 13px;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(0.5, 1.1)};
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(0.5)};
    background-color: #f4f4f4;
    color: ${({ theme: { palette } }: ThemeType) => palette.p_grey.text};
  }
`;
