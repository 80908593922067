import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const PositionCardContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.position-card {
    background: ${({ theme }) => theme.palette.p_white.full};
    border: ${({ theme: { palette } }: ThemeType) =>
      `1px solid ${palette.p_grey.borderSecondary}`};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    border-radius: ${({ theme: { spacing } }: ThemeType) => spacing(1)};

    & > .position-title {
      margin: 0;
      font-size: 16px;
    }

    & > .tags-container {
      display: flex;
      gap: ${({ theme: { spacing } }: ThemeType) => spacing(1.5)};
    }
  }
`;

export const PositionSearchContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.position-search-inputs {
    display: flex;
    gap: ${({ theme }) => theme.spacing(1.5)};
    & > * {
      flex: 1;
    }

    & > .search-input {
      height: 48px;
      background: ${({ theme }) => theme.palette.p_white.full};

      & > .MuiInputLabel-root {
        padding: 0;
      }
      & > .MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
      }

      & > * {
        height: 100%;
        font-size: 13px;
        padding: ${({ theme: { spacing } }: ThemeType) => spacing(0, 2)};
        & > fieldset {
          border: ${({ theme: { palette } }: ThemeType) =>
            `1px solid ${palette.p_grey.borderSecondary}`};
        }
        &.Mui-focused {
          & > fieldset {
            border-color: none;
          }
        }
        & > input,
        & > div {
          padding: 0;
        }
      }
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      flex-direction: column;
      & > * {
        flex: auto;
      }
      gap: ${({ theme }) => theme.spacing(2)};
    }
  }
`;

export const PositionListContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.positions-list {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(2)};
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding-left: ${({ theme }) => theme.spacing(0)};
      gap: ${({ theme }) => theme.spacing(1)};
      margin-bottom: ${({ theme }) => theme.spacing(4)};
    }
  }
`;
