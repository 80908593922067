import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const ContentContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box component="div" {...props} />
))`
  &.content-container {
    display: flex;
    flex-direction: column;
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(3)};
    & > h1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 27px;
    }
    & > p {
      font-size: 14px;
      line-height: 220%;
      color: ${({ theme: { palette } }: ThemeType) => palette.p_black.text};
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      flex-direction: column;
    }
  }
`;

export const DescriptionContainer = styled(
  (props: WithThemeProps & BoxProps) => <Box component="div" {...props} />
)`
  &.careers-description {
    min-height: 100vh;
    max-width: 1440px;
    margin: 0 auto;
    padding: ${({ theme: { spacing } }: ThemeType) => spacing(10)};
    gap: ${({ theme: { spacing } }: ThemeType) => spacing(10)};

    display: flex;
    & > * {
      flex: 1;
    }
    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      flex-direction: column;
      padding: ${({ theme: { spacing } }: ThemeType) => spacing(2)};
    }
  }
`;
