import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import classnames from "classnames";
import MenuItem from "@mui/material/MenuItem";
import { PositionSearchContainer } from "../styles";
import { PositionSearchInputProps } from "../types";
import SearchIcon from "assets/svg/search.svg";

const PositionSearchInput: React.FC<PositionSearchInputProps> = ({
  setSearchFilter,
  searchFilter,
  departmentFilter,
  setDepartmentFilter,
  departments,
}) => {
  const handleOnChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchFilter(e.target.value);
  };
  const handleOnChangeDepartment = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDepartmentFilter(e.target.value);
  };

  return (
    <PositionSearchContainer className="position-search-inputs">
      <TextField
        type="text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={searchFilter}
        onChange={handleOnChangeSearch}
        label="Search job"
        className={classnames("search-input", "position-search")}
      />

      <TextField
        id="department"
        select
        value={departmentFilter}
        label="Department"
        onChange={handleOnChangeDepartment}
        className={classnames("search-input", "department-select")}
      >
        {departments.map((item, index) => {
          return (
            <MenuItem value={item} key={index}>
              {item}
            </MenuItem>
          );
        })}
      </TextField>
    </PositionSearchContainer>
  );
};

export default PositionSearchInput;
