import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { ContentContainer } from "../styles";
import { WorkingWithUsProps } from "../types";
import { PrismicRichText } from "@prismicio/react";

const WorkingWithUs: React.FC<WorkingWithUsProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: allPrismicCareersPage {
        nodes {
          data {
            page_subtitle {
              richText
            }
            description {
              richText
            }
          }
        }
      }
    }
  `);
  const document = data.pageData.nodes[0].data;

  return (
    <ContentContainer className="content-container">
      <PrismicRichText field={document.page_subtitle.richText} />
      <PrismicRichText field={document.description.richText} />
    </ContentContainer>
  );
};

export default WorkingWithUs;
