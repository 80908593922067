import * as React from "react";
import { styled } from "@mui/system";
import Box, { BoxProps } from "@mui/material/Box";
import { WithThemeProps } from "utils/general";
import { ThemeType } from "utils/theme";

export const TopContainer = styled((props: WithThemeProps & BoxProps) => (
  <Box {...props} />
))`
  &.home-top {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    color: ${({ theme }) => theme.palette.primary.main};
    display: flex;
    flex-direction: column-reverse;
    padding: ${({ theme }) => theme.spacing("20vh", 10, 0, 10)};
    overflow: hidden;

    & > .page-title {
      position: relative;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      height: fit-content;
      & > h1 {
        font-size: 80px;
        font-family: "utopia-std-display";
        font-weight: normal;
      }
    }
    & > .max-width {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      bottom: 0;
      & > .back-to-careers-link {
        position: absolute;
        display: flex;
        align-items: center;
        left: 80px;
        bottom: 40px;
        & > .back-to-open-positions {
          padding: ${({ theme }) => theme.spacing(0, 2)};
        }
        & > .back-to-careers-icon {
          fill: ${({ theme }) => theme.palette.primary.main};
        }
      }
    }
    & > .background {
      max-width: 1280px;
    }

    ${({ theme }: ThemeType) => theme.breakpoints.down("md")} {
      padding: ${({ theme }) => theme.spacing("20vh", 2, 2, 2)};
      & > .background {
        max-width: unset;
        width: 160%;
      }
      & > .max-width {
        bottom: 0;

        & > .back-to-careers-link {
          left: ${({ theme }) => theme.spacing(2)};
          bottom: 0;
          font-size: 12px;
          & > .back-to-open-positions {
            padding: ${({ theme }) => theme.spacing(0, 0.5)};
          }
          & > .back-to-careers-icon {
            width: 20%;
          }
        }
      }
      & > .page-title {
        & > h1 {
          font-size: 48px;
        }
      }
    }
  }
`;
