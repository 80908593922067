import React, { useEffect, useState } from "react";
import PositionCard from "./position-card";
import PositionSearchInput from "./position-search";
import { PositionListContainer } from "./styles";
import { Position } from "./types";
import { graphql, useStaticQuery } from "gatsby";

interface PositionsListProps {}

const PositionsList: React.FC<PositionsListProps> = () => {
  const [searchFilter, setSearchFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("All");
  const [departmentList, setDepartmentList] = useState<string[]>(["All"]);

  const data = useStaticQuery(graphql`
    query {
      pageData: allPosition {
        nodes {
          uid
          department
          location {
            country
          }
          name
        }
      }
    }
  `);
  const positionList: Position[] = data.pageData.nodes;
  const departments = positionList.map((position) => position.department);

  useEffect(() => {
    if (departments.length > 0) {
      departments.map((department) => {
        if (departmentList.length > 0 && departmentList.includes(department))
          return;
        setDepartmentList([...departmentList, department]);
      });
    }
  }, [departments]);

  return (
    <PositionListContainer className="positions-list">
      <PositionSearchInput
        departments={departmentList}
        setSearchFilter={setSearchFilter}
        searchFilter={searchFilter}
        departmentFilter={departmentFilter}
        setDepartmentFilter={setDepartmentFilter}
      />

      {positionList
        .filter((position) =>
          departmentFilter !== "All"
            ? position.department === departmentFilter
            : position
        )
        .filter((position) =>
          position.name.toLowerCase().includes(searchFilter.toLowerCase())
        )
        .map((position, index) => {
          return (
            <PositionCard
              key={index}
              department={position.department ?? ""}
              location={position.location?.country ?? ""}
              title={position.name ?? ""}
              url={`/careers/${position.uid}`}
            />
          );
        })}
    </PositionListContainer>
  );
};

export default PositionsList;
