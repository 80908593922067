import React from "react";
import Seo from "components/common/seo";
import CareersTop from "./components/top";
import CareersDescription from "./components/description";
import { CareersContainer } from "./styles";

interface CareersProps {}

const Careers: React.FC<CareersProps> = () => {
  return (
    <>
      <Seo />

      <CareersContainer className="careers-container">
        <CareersTop />
        <CareersDescription />
      </CareersContainer>
    </>
  );
};

export default Careers;
